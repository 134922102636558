import { render, staticRenderFns } from "./finewine.vue?vue&type=template&id=a4fceb06&scoped=true&"
import script from "./finewine.vue?vue&type=script&lang=js&"
export * from "./finewine.vue?vue&type=script&lang=js&"
import style0 from "./finewine.vue?vue&type=style&index=0&id=a4fceb06&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4fceb06",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VHover,VImg})
